/* src/TextLoop.css */
.text-loop {
  /* position: relative; */
  /* height: 50px; Adjust height according to your needs */
  /* overflow: hidden; */
}

.text-item {
  position: absolute;
  width: 100%;
  display: flex;
  transition: opacity 0.7s ease-in-out;
  opacity: 0;
}

.text-item.visible {
  opacity: 1;
}

.text-item.hidden {
  opacity: 0;
}
